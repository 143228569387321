import React from "react";

export default function ColorVariationFacet({
  handleFilterClick,
  productColorVariations,
}) {
  function sortObject(obj) {
    return Object.keys(obj)
      .sort()
      .reduce((a, v) => {
        a[v] = obj[v];
        return a;
      }, {});
  }
  productColorVariations = sortObject(productColorVariations);

  return (
    <div class="facet-wrap facet-display">
      <strong>Color Variations</strong>
      <div className="facetwp-facet">
        {Object.keys(productColorVariations).map((variation, i) => {
          if (variation && productColorVariations[variation] > 0) {
            return (
              <div>
                <span
                  id={`variation-filter-${i}`}
                  key={i}
                  data-value={`${variation.toLowerCase()}`}
                  onClick={(e) =>
                    handleFilterClick(
                      "color_variation_facet",
                      e.target.dataset.value
                    )
                  }>
                  {" "}
                  {variation} {` (${productColorVariations[variation]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
