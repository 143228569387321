import React from "react";
import shaw_logo from "./BrandLogo/shaw_logo.jpg";
import anderson_tuftex_logo from "./BrandLogo/anderson_tuftex_logo.jpg";
import karastan_logo from "./BrandLogo/karastan_logo.jpg";
import armstrong_logo from "./BrandLogo/armstrong_logo.jpg";
import dreamweaver_logo from "./BrandLogo/dreamweaver_logo.jpg";
import philadelphia_commercial_logo from "./BrandLogo/philadelphia_commercial_logo.jpg";
import coretec_logo from "./BrandLogo/coretec_logo.jpg";
import daltile_logo from "./BrandLogo/daltile_logo.jpg";
import mohawk_logo from "./BrandLogo/mohawk_logo.jpg";
import bruce_logo from "./BrandLogo/bruce_logo.jpg";
import amrican_olean_logo from "./BrandLogo/amrican_olean_logo.jpg";
import mannington_logo from "./BrandLogo/mannington_logo.jpg";
import fabrica from "./BrandLogo/fabrica.jpg";
import masland from "./BrandLogo/masland.jpg";
import dixie from "./BrandLogo/dixie_home.jpg";
import nourison_logo from "./BrandLogo/nourison_logo.jpg";
import hydraluxe_logo from "./BrandLogo/hydraluxe_logo.jpg";
import karndean_logo from "./BrandLogo/karndean_logo.jpg";
import cali_logo from "./BrandLogo/cali_logo.jpg";
import robbins from "./BrandLogo/robbins.jpg";
import mercier from "./BrandLogo/mercier.jpg";
import hallmark from "./BrandLogo/hallmark.jpg";
import bella_cera from "./BrandLogo/bella_cera.jpg";
import godfrey_hirst from "./BrandLogo/godfrey_hirst.jpg";
import emsertile_logo from "./BrandLogo/emsertile_logo.jpg";
import marazzi from "./BrandLogo/marazzi.jpg";
import happy_feet from "./BrandLogo/happy_feet.jpg";
import happy_floors from "./BrandLogo/happy_floors.jpg";
import somerset from "./BrandLogo/somerset.jpg";
import naturally_aged_flooring from "./BrandLogo/naturally_aged_flooring.jpg";
import msi_tile from "./BrandLogo/msi_tile.jpg";
import stanton from "./BrandLogo/stanton.jpg";
import kingsmen from "./BrandLogo/kingsmen_logo.jpg";
// import ColorDestinationLogo from "./BrandLogo/Color_Destination_Logo_1.jpg";
// import CarpetsPlusPetPerfDestinationLogo from "./BrandLogo/CarpetsPlus_Pet_Perf_Destination_Logo.jpg";
// import ColorDestinationWhite1Logo from "./BrandLogo/Color_Destination_White_1_Logo.jpg";
// import COLORTILEHDLuxVinylFlooringBlack2 from "./BrandLogo/COLORTILE_HD_Lux_Vinyl_Flooring_Black_2.jpg";
// import COLORTILENatureMarkHWlogo800px from "./BrandLogo/COLORTILE_NatureMark_HW_logo_800px.jpg";
// import COLORTILEPrimeLuxuryVinylFlooringwithCTtech from "./BrandLogo/COLORTILE_Prime_Luxury_Vinyl_Flooring_with_CT_tech.jpg";
// import COLORTILEProElitePerformancePlankftCOREtecTechblack2 from "./BrandLogo/COLORTILE_Pro_Elite_Performance_Plank_ft_COREtecTech_black2.jpg";
// import COLORTILEProLuxVinylPlankLogo500px2 from "./BrandLogo/COLORTILE_Pro_Lux_Vinyl_Plank_Logo_500px_2.jpg";
// import COLORTILEProWaterproofPerformanceFlooringLVP from "./BrandLogo/COLORTILE_Pro_Waterproof_Performance_Flooring_LVP.jpg";
// import COLORTILESelectLVFlooringLogo2 from "./BrandLogo/COLORTILE_Select_LV_Flooring_Logo_2.jpg";
// import COLORTILESelectPremierLVFlooringBlackwithCTtech from "./BrandLogo/COLORTILE_Select_Premier_LV_Flooring_Black_with_CT_tech.jpg";
// import COLORTILEUltraHDSignatureFlooringLogo2 from "./BrandLogo/COLORTILE_Ultra_HD_Signature_Flooring_Logo_2.jpg";
// import ElitePerformanceHomeLogoBLACK from "./BrandLogo/Elite_Performance_Home_Logo_BLACK.jpg";
// import HardwoodDestinationLogo from "./BrandLogo/Hardwood_Destination_Logo.jpg";
// import HomeGallery10fotorbgremover20230818113248 from "./BrandLogo/Home_Gallery_1.0_fotor_bg_remover_20230818113248.jpg";
// import HomeGallery20fotorbgremover20230818113442 from "./BrandLogo/Home_Gallery_2.0_fotor_bg_remover_20230818113442.jpg";
// import Korlok1536x439fotorbgremover20230818114347 from "./BrandLogo/Korlok_1536x439_fotor_bg_remover_20230818114347.jpg";
// import LuxuryFlooringDestination1logo from "./BrandLogo/Luxury_Flooring_Destination_1_logo.jpg";
// import milancollectionlogo from "./BrandLogo/milan_collection_product_page_header.jpg";
// import PureColorDestinationLogo12048x857 from "./BrandLogo/PureColor_Destination_Logo_1_2048x857.jpg";

function ProductBrandLogos({ product }) {
  return (
    <div>
      {product.brand_facet.toLowerCase() === "shaw floors" ||
      product.brand_facet.toLowerCase() === "shaw" ? (
        <img src={shaw_logo} alt="Shaw Logo" />
      ) : product.brand_facet.toLowerCase() === "anderson tuftex" ? (
        <img src={anderson_tuftex_logo} alt="Anderson Tuftex" />
      ) : product.brand_facet.toLowerCase() === "karastan" ? (
        <img src={karastan_logo} alt="Karastan" />
      ) : product.brand_facet.toLowerCase() === "armstrong" ? (
        <img src={armstrong_logo} alt="Armstrong" />
      ) : product.brand_facet.toLowerCase() === "dreamweaver" ||
        product.brand_facet.toLowerCase() === "dream weaver" ? (
        <img src={dreamweaver_logo} alt="Dream Weaver" />
      ) : product.brand_facet.toLowerCase() === "philadelphia commercial" ? (
        <img src={philadelphia_commercial_logo} alt="Philadelphia Commercial" />
      ) : product.brand_facet.toLowerCase() === "coretec" ? (
        <img src={coretec_logo} alt="COREtec" />
      ) : product.brand_facet.toLowerCase() === "daltile" ? (
        <img src={daltile_logo} alt="Daltile" />
      ) : product.brand_facet.toLowerCase() === "mohawk" ? (
        <img src={mohawk_logo} alt="Mohawk" />
      ) : product.brand_facet.toLowerCase() === "bruce" ? (
        <img src={bruce_logo} alt="Bruce" />
      ) : product.brand_facet.toLowerCase() === "american olean" ? (
        <img src={amrican_olean_logo} alt="American Olean" />
      ) : product.brand_facet.toLowerCase() === "mannington" ? (
        <img src={mannington_logo} alt="Mannington" />
      ) : product.brand_facet.toLowerCase() === "fabrica" ? (
        <img src={fabrica} alt="Fabrica" />
      ) : product.brand_facet.toLowerCase() === "masland" ? (
        <img src={masland} alt="Masland" />
      ) : product.brand_facet.toLowerCase() === "dixie home" ? (
        <img src={dixie} alt="Dixie Home" />
      ) : product.brand_facet.toLowerCase() === "nourison" ? (
        <img src={nourison_logo} alt="Nourison" />
      ) : product.brand_facet.toLowerCase() === "hydraluxe" ? (
        <img src={hydraluxe_logo} alt="Hydraluxe" />
      ) : product.brand_facet.toLowerCase() === "karndean" ? (
        <img src={karndean_logo} alt="Karndean" />
      ) : product.brand_facet.toLowerCase() === "cali" ? (
        <img src={cali_logo} alt="Cali" />
      ) : product.brand_facet.toLowerCase() === "robbins" ? (
        <img src={robbins} alt="Robbins" />
      ) : product.brand_facet.toLowerCase() === "mercier" ? (
        <img src={mercier} alt="Mercier" />
      ) : product.brand_facet.toLowerCase() === "hallmark" ? (
        <img src={hallmark} alt="Hallmark" />
      ) : product.brand_facet.toLowerCase() === "bella cera" ? (
        <img src={bella_cera} alt="Bella Cera" />
      ) : product.brand_facet.toLowerCase() === "godfrey hirst" ? (
        <img src={godfrey_hirst} alt="Godfrey Hirst" />
      ) : product.brand_facet.toLowerCase() === "stanton" ? (
        <img src={stanton} alt="Stanton" />
      ) : product.brand_facet.toLowerCase() === "emser" ? (
        <img src={emsertile_logo} alt="Emser" />
      ) : product.brand_facet.toLowerCase() === "marazzi" ? (
        <img src={marazzi} alt="Marazzi" />
      ) : product.brand_facet.toLowerCase() === "happy feet" ? (
        <img src={happy_feet} alt="Happy Feet" />
      ) : product.brand_facet.toLowerCase() === "happy floors" ? (
        <img src={happy_floors} alt="Happy Floors" />
      ) : product.brand_facet.toLowerCase() === "somerset" ? (
        <img src={somerset} alt="Somerset" />
      ) : product.brand_facet.toLowerCase() === "naturally aged flooring" ? (
        <img src={naturally_aged_flooring} alt="Naturally Aged Flooring" />
      ) : product.brand_facet.toLowerCase() === "msi tile" ? (
        <img src={msi_tile} alt="MSI Tile" />
      ) : product.brand_facet.toLowerCase() === "kingsmen" ? (
        <img src={kingsmen} alt="Kingsmen" />
      ) : product.collection_facet.toLowerCase() ===
        "pet performance destination" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/CarpetsPlus_Pet_Perf_Destination_Logo.jpg"
          alt="Pet Performance Destination"
        />
      ) : product.collection_facet.toLowerCase() ===
        "color destination carpet collection" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/Color_Destination_Logo_1.jpg"
          alt="Color Destination Carpet Collection"
        />
      ) : product.collection_facet.toLowerCase() ===
        "pure color destination" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/PureColor_Destination_Logo_1_2048x857.jpg"
          alt="Pure Color Destination"
        />
      ) : product.collection_facet.toLowerCase() === "color destination" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/Color_Destination_White_1_Logo.jpg"
          alt="Color Destination"
        />
      ) : product.collection_facet.toLowerCase() ===
        "elite performance home" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/Elite_Performance_Home_Logo_BLACK.jpg"
          alt="Elite Performance Home"
        />
      ) : product.collection_facet.toLowerCase() === "milan collection" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/milan_collection_product_page_header.jpg"
          alt="Milan Collection"
        />
      ) : product.collection_facet.toLowerCase() ===
        "colortile prime luxury vinyl flooring" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/COLORTILE_Prime_Luxury_Vinyl_Flooring_with_CT_tech.jpg"
          alt="Colortile Prime Luxury Vinyl Flooring"
        />
      ) : product.collection_facet.toLowerCase() ===
        "colortile hd luxury vinyl flooring" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/COLORTILE_HD_Lux_Vinyl_Flooring_Black_2.jpg"
          alt="Colortile HD Luxury Vinyl Flooring"
        />
      ) : product.collection_facet.toLowerCase() ===
        "colortile pro luxury vinyl flooring" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/COLORTILE_Pro_Lux_Vinyl_Plank_Logo_500px_2.jpg"
          alt="Colortile Pro Luxury Vinyl Flooring"
        />
      ) : product.collection_facet.toLowerCase() ===
        "colortile pro luxury vinyl tile" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/COLORTILE_Pro_Lux_Vinyl_Plank_Logo_500px_2.jpg"
          alt="Colortile Pro Luxury Vinyl Tile"
        />
      ) : product.collection_facet.toLowerCase() ===
        "colortile pro luxury vinyl plank" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/COLORTILE_Pro_Lux_Vinyl_Plank_Logo_500px_2.jpg"
          alt="Colortile Pro Luxury Vinyl Plank"
        />
      ) : product.collection_facet.toLowerCase() ===
        "colortile pro waterproof performance flooring" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/COLORTILE_Pro_Waterproof_Performance_Flooring_LVP.jpg"
          alt="Colortile Pro Waterproof Performance Flooring"
        />
      ) : product.collection_facet.toLowerCase() ===
        "colortile select luxury vinyl flooring" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/COLORTILE_Select_LV_Flooring_Logo_2.jpg"
          alt="Colortile Select Luxury Vinyl Flooring"
        />
      ) : product.collection_facet.toLowerCase() ===
        "colortile select premier luxury vinyl flooring" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/COLORTILE_Select_Premier_LV_Flooring_Black_with_CT_tech.jpg"
          alt="Colortile Select Premier Luxury Vinyl Flooring"
        />
      ) : product.collection_facet.toLowerCase() === "hardwood destination" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/Hardwood_Destination_Logo.jpg"
          alt="Hardwood Destination"
        />
      ) : product.collection_facet.toLowerCase() ===
        "colortile ultra hd signature flooring" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/COLORTILE_Ultra_HD_Signature_Flooring_Logo_2.jpg"
          alt="Colortile Ultra HD Signature Flooring"
        />
      ) : product.collection_facet.toLowerCase() === "home gallery tile 1.0" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/Home_Gallery_1.0_fotor_bg_remover_20230818113248.jpg"
          alt="Home Gallery Tile 1.0"
        />
      ) : product.collection_facet.toLowerCase() === "home gallery tile 2.0" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/Home_Gallery_2.0_fotor_bg_remover_20230818113442.jpg"
          alt="Home Gallery Tile 2.0"
        />
      ) : product.collection_facet.toLowerCase() ===
        "colortile naturemark waterproof hardwood" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/COLORTILE_NatureMark_HW_logo_800px.jpg"
          alt="Colortile Naturemark Waterproof Hardwood"
        />
      ) : product.collection_facet.toLowerCase() ===
        "luxury flooring destination 2.0" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/Luxury_Flooring_Destination_1_logo.jpg"
          alt="Luxury Flooring Destination 2.0"
        />
      ) : product.collection_facet.toLowerCase() === "color destination" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/Color_Destination_White_1_Logo.jpg"
          alt="Color Destination"
        />
      ) : product.collection_facet.toLowerCase() ===
        "elite performance waterproof flooring" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/COLORTILE_Pro_Elite_Performance_Plank_ft_COREtecTech_black2.jpg"
          alt="Elite Performance Waterproof Flooring"
        />
      ) : product.collection_facet.toLowerCase() ===
        "colortile design statement flooring" ? (
        <img
          src="/wp-content/plugins/mm-product-listing/build/BrandLogo/Korlok_1536x439_fotor_bg_remover_20230818114347.jpg"
          alt="Colortile Design Statement Flooring"
        />
      ) : (
        <p>{product.brand_facet}</p>
      )}
    </div>
  );
}

export default ProductBrandLogos;
