import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function PdpGalleryPopup({
  swatch,
  galleryImages,
  height,
  width,
  productName,
}) {
  const [nav1, setNav1] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slider1, setSlider1] = useState(null);

  useEffect(() => {
    setNav1(slider1);
  }, [slider1]);

  var settings = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    onReInit: () => setCurrentSlide(slider1?.innerSlider.state.currentSlide),
    lazyLoad: true,
    asNavFor: ".slider-nav",
    focusOnSelect: true,
  };

  var settings1 = {
    variableWidth: !0,
    dots: !1,
    arrows: !1,
    infinite: !1,
    slidesToScroll: 2,
    slidesToShow: 2,
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 4,
    //     },
    //   },
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 6,
    //     },
    //   },
    // ],
  };
  return (
    <>
      <Slider
        {...settings}
        asNavFor={nav1}
        ref={(slider) => setSlider1(slider)}>
        <div className="popup-imgs-holder1">
          <a href="javascript:void(0)">
            <span className="main-imgs">
              <img
                src={`https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://${swatch}`}
                alt=""
              />
            </span>
          </a>
        </div>

        {galleryImages &&
          galleryImages.split("|").map((image, index) => (
            <div key={index} className="popup-imgs-holder1">
              <a href="javascript:void(0)">
                <span className="main-imgs">
                  <img
                    src={`https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://${image}`}
                    alt=""
                  />
                </span>
              </a>
            </div>
          ))}
      </Slider>
      <div className="thumb-wrapper">
        <h4 className="product-title">{productName}</h4>
        <div className="thumb-img-wrapper">
          {/* <div className="thumb-img"> */}
          <Slider {...settings1}>
            <div
              className={currentSlide === 0 ? "active" : null}
              onClick={() => {
                slider1?.slickGoTo(0);
              }}>
              <img
                src={`https://mm-media-res.cloudinary.com/image/fetch/h_100,w_100,c_limit/https://${swatch}`}
              />
            </div>

            {galleryImages &&
              galleryImages.split("|").map((image, index) => (
                <div
                  className={currentSlide === index + 1 ? "active" : null}
                  onClick={() => {
                    slider1?.slickGoTo(index + 1);
                  }}>
                  <img
                    src={`https://mm-media-res.cloudinary.com/image/fetch/h_100,w_100,c_limit/https://${image}`}
                  />
                </div>
              ))}
          </Slider>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

// <Slider {...settings}>
//   <div
//     className="popup-imgs-holder1"
//     data-targetimg={`gallery_item_0`}
//     data-responsive={`https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://${swatch}`}
//     data-exthumbimage={`https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://${swatch}`}
//     data-src={`https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://${swatch}`}>
//     <a href="javascript:void(0)">
//       <span className="main-imgs">
//         <img
//           src={`https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://${swatch}`}
//           alt=""
//         />
//       </span>
//     </a>
//   </div>

//   {galleryImages.split("|").map((image, index) => (
//     <div
//       key={index}
//       className="popup-imgs-holder1"
//       data-targetimg={`gallery_item_${index + 1}`}
//       data-responsive={`https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://${image}`}
//       data-exthumbimage={`https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://${image}`}
//       data-src={`https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://${image}`}>
//       <a href="javascript:void(0)">
//         <span className="main-imgs">
//           <img
//             src={`https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://${image}`}
//             alt=""
//           />
//         </span>
//       </a>
//     </div>
//   ))}
// </Slider>
