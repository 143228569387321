import React from "react";

export default function HeightFacet({ handleFilterClick, productHeight }) {
  function sortObject(obj) {
    return Object.keys(obj)
      .sort()
      .reduce((a, v) => {
        a[v] = obj[v];
        return a;
      }, {});
  }
  productHeight = sortObject(productHeight);

  return (
    <div class="facet-wrap facet-display">
      <strong>Height</strong>
      <div className="facetwp-facet">
        {Object.keys(productHeight).map((height, i) => {
          if (height && productHeight[height] > 0) {
            return (
              <div>
                <span
                  id={`height-filter-${i}`}
                  key={i}
                  data-value={`${height.toLowerCase()}`}
                  onClick={(e) =>
                    handleFilterClick("height", e.target.dataset.value)
                  }>
                  {" "}
                  {height} {` (${productHeight[height]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
