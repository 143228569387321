import React from "react";

export default function SpeciesFacet({ handleFilterClick, productSpecies }) {
  function sortObject(obj) {
    return Object.keys(obj)
      .sort()
      .reduce((a, v) => {
        a[v] = obj[v];
        return a;
      }, {});
  }
  productSpecies = sortObject(productSpecies);
  return (
    <div class="facet-wrap facet-display">
      <strong>Species</strong>
      <div className="facetwp-facet">
        {Object.keys(productSpecies).map((species, i) => {
          if (species && productSpecies[species] > 0) {
            return (
              <div>
                <span
                  id={`species-filter-${i}`}
                  key={i}
                  data-value={`${species.toLowerCase()}`}
                  onClick={(e) =>
                    handleFilterClick("species_facet", e.target.dataset.value)
                  }>
                  {" "}
                  {species} {` (${productSpecies[species]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
