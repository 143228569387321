import React from "react";

export default function LookFacet({ handleFilterClick, productLook }) {
  function sortObject(obj) {
    return Object.keys(obj)
      .sort()
      .reduce((a, v) => {
        a[v] = obj[v];
        return a;
      }, {});
  }
  productLook = sortObject(productLook);

  return (
    <div class="facet-wrap facet-display">
      <strong>Look</strong>
      <div className="facetwp-facet">
        {Object.keys(productLook).map((look, i) => {
          if (look && productLook[look] > 0) {
            return (
              <div>
                <span
                  id={`look-filter-${i}`}
                  key={i}
                  data-value={`${look.toLowerCase()}`}
                  onClick={(e) =>
                    handleFilterClick("look_facet", e.target.dataset.value)
                  }>
                  {" "}
                  {look} {` (${productLook[look]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
