import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function VerticleGalleryImages({
  swatch,
  gallery_images,
  width,
  height,
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index); // Set the active index
  };

  let swatchimg = `https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://`;
  if (window.innerWidth > 991) {
    var settings = {
      dots: !1,
      infinite: !1,
      arrows: !0,
      slidesToShow: 6,
      slidesToScroll: 3,
      vertical: true,
      verticalSwiping: true,
      mobileFirst: !0,
      draggable: !1,
    };
  } else {
    var settings = {
      dots: !1,
      infinite: !1,
      arrows: !0,
      slidesToShow: 4,
      slidesToScroll: 2,
      adaptiveWidth: !0,
      mobileFirst: !0,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    };
  }
  return (
    <div class="toggle-image-thumbnails ">
      <Slider {...settings}>
        <div
          // class="toggle-image-holder active"
          onClick={() => handleClick(0)}
          className={
            activeIndex === 0
              ? "toggle-image-holder active"
              : "toggle-image-holder"
          }
          key={0}>
          <a
            href="javascript:void(0)"
            style={{
              backgroundImage: `url(${swatchimg}${swatch})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            data-targetimg={`gallery_item_0`}
            data-background={`${swatchimg}${swatch}`}
            data-fr-replace-bg=".toggle-image">
            <img
              key="0"
              src={`${swatchimg}${swatch}`}
              width={width}
              height={height}
            />
          </a>
        </div>
        {gallery_images.split("|").map((image, index) => (
          <div
            onClick={() => handleClick(index + 1)}
            className={
              activeIndex === index + 1
                ? "toggle-image-holder active"
                : "toggle-image-holder"
            }
            key={index + 1}>
            <a
              href="javascript:void(0)"
              style={{
                backgroundImage: `url(${swatchimg}${image})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              data-targetimg={`gallery_item_${index + 1}`}
              data-background={`${swatchimg}${image}`}
              data-fr-replace-bg=".toggle-image">
              <img src={`${swatchimg}${image}`} width={width} height={height} />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
}
