import React, { useState, useEffect } from "react";
import "./Products.css";
import ProductLoop from "./components/ProductLoop";
import Loader from "./components/Loader";

function ProductLoopRow() {
  const [products, setProducts] = useState([]);
  const wpProductCategory = window.wpProductCategory;
  const wpProductFilter = window.wpProductFilter;
  const catColor = wpProductCategory.color_facet
    ? wpProductCategory.color_facet.toLowerCase()
    : "";
  const catBrandFacet = wpProductCategory.brand_facet
    ? wpProductCategory.brand_facet.toLowerCase()
    : "";
  const catApplicationFacet = wpProductCategory.application_facet
    ? wpProductCategory.application_facet.toLowerCase()
    : "";
  const catCollectionFacet = wpProductCategory.collection_facet
    ? wpProductCategory.collection_facet.toLowerCase()
    : "";
  const catStyleFacet = wpProductCategory.style_facet
    ? wpProductCategory.style_facet.toLowerCase()
    : "";
  const catShadeFacet = wpProductCategory.shade_facet
    ? wpProductCategory.shade_facet.toLowerCase()
    : "";
  const catShapeFacet = wpProductCategory.shape_facet
    ? wpProductCategory.shape_facet.toLowerCase()
    : "";
  const catFiberFacet = wpProductCategory.fiber
    ? wpProductCategory.fiber.toLowerCase()
    : "";
  const queryParams = new URLSearchParams(window.location.search);

  const filterObj = {
    color_facet: catColor,
    brand_facet: catBrandFacet,
    collection_facet: catCollectionFacet,
    application_facet: catApplicationFacet,
    style_facet: catStyleFacet,
    shade_facet: catShadeFacet,
    shape_facet: catShapeFacet,
    fiber: catFiberFacet,
  };

  let filteredObj1 = Object.fromEntries(
    Object.entries(filterObj).filter(([_, v]) => v != "")
  );
  const [filters, setFilters] = useState(filteredObj1);

  const paramLimit = wpProductCategory.limit ? wpProductCategory.limit : 4;
  const [itemsPerPage, setItemsPerPage] = useState(paramLimit);
  const inStock = wpProductCategory.inStock ? wpProductCategory.inStock : "";

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchProducts();
  }, [filters]);

  const fetchProducts = async () => {
    const query = new URLSearchParams({
      category: wpProductCategory.category,
      ...filters,
      limit: itemsPerPage,
      in_stock: inStock,
    });

    try {
      const response = await fetch(`${wpProductFilter.apiEndpoint}?${query}`);
      const data = await response.json();
      setProducts(data.products_group_by);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="product-list-container">
        {loading ? (
          <Loader />
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {Object.keys(products).length > 0 && (
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 products-list ">
                        <div className="product-plp-grid product-grid swatch facetwp-template1">
                          <div className="row product-row">
                            {Object.keys(products).map((product, i) => {
                              return (
                                <ProductLoop
                                  key={i}
                                  products={products[product]}
                                  rows={paramLimit}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {Object.keys(products).length == 0 && loading == false && (
                  <div className="col-lg-9 col-md-9 col-sm-12 product-list-container">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                      <h2>No Products Available</h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ProductLoopRow;
