import React from "react";

const GravityFormDisplay = () => {

  const gravityroot = document
        .getElementById("gravity-form-root");

    const gravityFormHTML = 
    gravityroot?.getAttribute("data-gravity-form");
                            
    
  return (
    <div>
        <section className="gravity-form-section"
        dangerouslySetInnerHTML={{ __html:
            gravityFormHTML }}
        >   
        </section>
    </div>
  );
};

export default GravityFormDisplay;