import React, { useState, useEffect } from "react";
import "./Products.css";
import ProductDetailPage from "./components/ProductDetailPage";
import ProductDetailPageGravity from "./components/ProductDetailPageGravity";
import { useParams, useLocation } from "react-router-dom";
import Loader from "./components/Loader";

function ProductsDetails() {
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState([]);
  const wpProductFilter = window.wpProductFilter;
  const wpProductCategory = window.wpProductCategory;
  const sku = wpProductCategory.sku;
  const pdpshowform = wpProductFilter.siteInfo.pdpshowform
    ? wpProductFilter.siteInfo.pdpshowform
    : 0;
  const pdpformshortcode = wpProductFilter.siteInfo.pdpshowforminstock
    ? wpProductFilter.siteInfo.pdpshowforminstock
    : 0;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (sku) {
      fetchProducts();
    }
    // fetchFilterCounts();
  }, [sku]);

  const fetchProducts = async () => {
    const query = new URLSearchParams({
      sku: wpProductCategory.sku,
      category: wpProductCategory.category,
      pageName: "pdp",
    });

    try {
      const response = await fetch(`${wpProductFilter.apiEndpoint}?${query}`);
      const data = await response.json();
      var product1 = data.products_group_by;
      setProducts(product1);
      setCurrentProduct(product1[Object.keys(product1)[0]][0]);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="product-list-container">
      {loading && <Loader />}
      {Object.keys(currentProduct) &&
      currentProduct.in_stock == 1 &&
      pdpshowform == 1 &&
      pdpformshortcode != "" ? (
        <div className="product-detail-layout-6 instock-with-form">
          {Object.keys(products)?.map((product, i) => {
            if (i === 0) {
              return (
                <ProductDetailPageGravity
                  key={i}
                  product={products[product][0]}
                  type={wpProductCategory.category}
                  sku={wpProductCategory.sku}
                />
              );
            }
          })}
        </div>
      ) : (
        <div className="product-detail-layout-6">
          {Object.keys(products)?.map((product, i) => {
            if (i === 0) {
              return (
                <ProductDetailPage
                  key={i}
                  product={products[product][0]}
                  type={wpProductCategory.category}
                  sku={wpProductCategory.sku}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
}

export default ProductsDetails;
