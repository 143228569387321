import React from "react";
import ColorSliderElementPdp from "./ColorSliderElementPdp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function ColorSliderPdp({
  products,
  currentProductSku,
  currentProductColor,
}) {
  let specificKey;
  let specificValue;
  if (currentProductColor !== "" && currentProductColor !== undefined) {
    specificKey = "color";
    specificValue = currentProductColor;
  } else {
    specificKey = "sku";
    specificValue = currentProductSku;
  }

  if (products) {
    const specificObject = products.find(
      (obj) => obj[specificKey] === specificValue
    );

    const remainingObjects = products.filter(
      (obj) => obj[specificKey] !== specificValue
    );

    products = [specificObject, ...remainingObjects];
  }
  var settings = {
    dots: !1,
    infinite: !1,
    arrows: !0,
    slidesToShow: 4,
    slidesToScroll: 2,
    mobileFirst: !0,
    draggable: !1,
    // responsive: [
    //   { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 4 } },
    //   { breakpoint: 820, settings: { slidesToShow: 3, slidesToScroll: 3 } },
    //   { breakpoint: 520, settings: { slidesToShow: 2, slidesToScroll: 2 } },
    //   { breakpoint: 320, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    // ],
  };

  return (
    <div className="product-variations">
      <div className="color_variations_slider_1">
        <div className="slides">
          <Slider {...settings}>
            {products?.map((product, index) => {
              return (
                product && (
                  <ColorSliderElementPdp
                    key={index}
                    count={index}
                    product={product}
                    currentProductSku={currentProductSku}
                    currentProductColor={currentProductColor}
                  />
                )
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
