import React from "react";

export default function ConstructionFacet({
  handleFilterClick,
  productConstructions,
}) {
  function sortObject(obj) {
    return Object.keys(obj)
      .sort()
      .reduce((a, v) => {
        a[v] = obj[v];
        return a;
      }, {});
  }
  productConstructions = sortObject(productConstructions);

  return (
    <div class="facet-wrap facet-display">
      <strong>Constructions</strong>
      <div className="facetwp-facet">
        {Object.keys(productConstructions).map((constructions, i) => {
          if (constructions && productConstructions[constructions] > 0) {
            return (
              <div>
                <span
                  id={`construction-filter-${i}`}
                  key={i}
                  data-value={`${constructions.toLowerCase()}`}
                  onClick={(e) =>
                    handleFilterClick(
                      "construction_facet",
                      e.target.dataset.value
                    )
                  }>
                  {" "}
                  {constructions} {` (${productConstructions[constructions]}) `}
                </span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
