import React, { useEffect } from "react";
import Swatch from "./Swatch";

export default function ProductLoopSearch({ product }) {
  const wpSearchProducts = window.wpSearchProducts;
  let rewrite_slug = "";
  const product_cat = product.z_prod_type;
  if (
    wpSearchProducts &&
    wpSearchProducts.instocksyncseprate != "" &&
    wpSearchProducts.instocksyncseprate != undefined
  ) {
    const rewrite_slug_type =
      wpSearchProducts.instocksyncseprate === "1" && product.in_stock === 1
        ? "instock"
        : "normal";
    const rewrite_category =
      wpSearchProducts.categories[product_cat][rewrite_slug_type];

    rewrite_slug = wpSearchProducts.rewrite_rules[rewrite_category][
      "rewrite_slug"
    ]
      ? wpSearchProducts.rewrite_rules[rewrite_category]["rewrite_slug"]
      : "";
  }
  let url_slug = product.url_key;
  if (rewrite_slug !== "" && rewrite_slug !== undefined) {
    const segments = url_slug.split("/");
    url_slug = "/" + rewrite_slug + "/" + segments[segments.length - 1];
  }
  url_slug += url_slug.endsWith("/") ? "" : "/";

  return (
    <div className="product-item col-lg-4 col-md-4 col-sm-6 ">
      <div className="fl-post-grid-post">
        <div className="fl-post-grid-image">
          <Swatch
            swatch={product.swatch}
            pdpUrl={`${window.location.origin}${url_slug}`}
            height={222}
            width={222}
          />
        </div>
        <div className="fl-post-grid-text product-grid btn-grey">
          <h4>
            <span>{product.collection_facet}</span>
            <span>{product.brand}</span>
          </h4>

          {/* <div className="product-variations1">
            <h5>{`${products?.length} COLORS AVAILABLE`}</h5>
            <div className="product-color-variationPlp plp_slide_wrap">
              <ColorSlider products={products} page={"plp"} />
            </div>
          </div> */}
          <a
            href={`${window.location.origin}${url_slug}`}
            className="fl-button plp_box_btn">
            View Product
          </a>
        </div>
      </div>
    </div>
  );
}
