import React from "react";
// import { Link } from "react-router-dom";

export default function Swatch({ swatch, pdpUrl, width, height }) {
  let swatchimg = `https://mm-media-res.cloudinary.com/image/fetch/h_${height},w_${width},c_limit/https://${swatch}`;

  return (
    <div class="swatch-img-holder">
      <a href={`${pdpUrl}`}>
        <img
          src={swatchimg}
          width={width}
          height={height}
          className="swatch-img"
        />
      </a>
    </div>
  );
}
