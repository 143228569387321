import React from "react";

export default function SizeAvailables({ products, sku }) {
  const wpProductCategory = window.wpProductCategory;

  const uniqueItems = Array.from(
    new Map(
      products
        .filter((item) => item.size && item.size !== "")
        .map((item) => [item.size, item])
    ).values()
  );

  return (
    Object.keys(uniqueItems).length > 0 && (
      <div className="row product_size_section">
        <div className="col-md-12">
          <div className="product-colors">
            <ul className="available_size_ul">
              <li className="colors">Size(s) Available</li>
            </ul>
          </div>
          <div className="product-sizes-wrapper">
            <ul className="product-sizes">
              {Object.keys(uniqueItems).map((product, i) => {
                let url_slug = products[product].url_key;
                if (
                  wpProductCategory.rewrite_slug !== "" &&
                  wpProductCategory.rewrite_slug !== undefined
                ) {
                  const segments = url_slug.split("/");
                  url_slug =
                    "/" +
                    wpProductCategory.rewrite_slug +
                    "/" +
                    segments[segments.length - 1];
                }
                url_slug += url_slug.endsWith("/") ? "" : "/";

                var activeClass =
                  uniqueItems[product].sku === sku ? "active-size" : "";
                return (
                  <li className={`product-size-item ${activeClass}`} key={i}>
                    <a href={`${window.location.origin}${url_slug}`}>
                      {uniqueItems[product].size}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    )
  );
}
