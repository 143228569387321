const ProductAttributes = ({ product }) => {
  return (
    <div class="product-attributes">
      <h3>Product Attributes</h3>
      <table class="table">
        <thead></thead>
        <tbody>
          {product.collection_facet && (
            <tr>
              <th scope="row">Collection</th>
              <td>{product.collection_facet}</td>
            </tr>
          )}
          {product.color_facet && (
            <tr>
              <th scope="row">Color</th>
              <td>{product.color_facet}</td>
            </tr>
          )}
          {product.brand && (
            <tr>
              <th scope="row">Brand</th>
              <td>{product.brand}</td>
            </tr>
          )}
          {product.construction && (
            <tr>
              <th scope="row">Construction</th>
              <td>{product.construction}</td>
            </tr>
          )}
          {product.color_variation_facet && (
            <tr>
              <th scope="row">Color Variation</th>
              <td>{product.color_variation_facet}</td>
            </tr>
          )}
          {product.core && (
            <tr>
              <th scope="row">Core</th>
              <td>{product.core}</td>
            </tr>
          )}
          {product.species && (
            <tr>
              <th scope="row">Species</th>
              <td>{product.species}</td>
            </tr>
          )}
          {product.shade && (
            <tr>
              <th scope="row">Shade</th>
              <td>{product.shade}</td>
            </tr>
          )}
          {product.shape && (
            <tr>
              <th scope="row">Shape</th>
              <td>{product.shape}</td>
            </tr>
          )}
          {product.surface_type && (
            <tr>
              <th scope="row">Surface Type</th>
              <td>{product.surface_type}</td>
            </tr>
          )}
          {product.surface_texture_facet && (
            <tr>
              <th scope="row">Surface Texture</th>
              <td>{product.surface_texture_facet}</td>
            </tr>
          )}
          {product.edge && (
            <tr>
              <th scope="row">Edge</th>
              <td>{product.edge}</td>
            </tr>
          )}
          {product.application && (
            <tr>
              <th scope="row">Application</th>
              <td>{product.application}</td>
            </tr>
          )}
          {product.core && (
            <tr>
              <th scope="row">Core</th>
              <td>{product.core}</td>
            </tr>
          )}
          {product.size && (
            <tr>
              <th scope="row">Size</th>
              <td>{product.size}</td>
            </tr>
          )}
          {product.width && (
            <tr>
              <th scope="row">Width</th>
              <td>{product.width}</td>
            </tr>
          )}
          {product.length && (
            <tr>
              <th scope="row">Length</th>
              <td>{product.length}</td>
            </tr>
          )}
          {product.thickness && (
            <tr>
              <th scope="row">Thickness</th>
              <td>{product.thickness}</td>
            </tr>
          )}
          {product.finish && (
            <tr>
              <th scope="row">Finish Coating</th>
              <td>{product.finish}</td>
            </tr>
          )}
          {product.fiber && (
            <tr>
              <th scope="row">Fiber</th>
              <td>{product.fiber}</td>
            </tr>
          )}
          {product.fiber_type && (
            <tr>
              <th scope="row">Fiber Type</th>
              <td>{product.fiber_type}</td>
            </tr>
          )}
          {product.face_weight && (
            <tr>
              <th scope="row">Face Weight</th>
              <td>{product.face_weight}</td>
            </tr>
          )}
          {product.pattern_repeat && (
            <tr>
              <th scope="row">Pattern Repeat</th>
              <td>{product.pattern_repeat}</td>
            </tr>
          )}
          {product.location && (
            <tr>
              <th scope="row">Location</th>
              <td>{product.location}</td>
            </tr>
          )}
          {product.installation_facet && (
            <tr>
              <th scope="row">Installation Method</th>
              <td>{product.installation_facet}</td>
            </tr>
          )}
          {product.style && (
            <tr>
              <th scope="row">Style</th>
              <td>{product.style}</td>
            </tr>
          )}
          {product.material && (
            <tr>
              <th scope="row">Material</th>
              <td>{product.material}</td>
            </tr>
          )}
          {product.core_thickness && (
            <tr>
              <th scope="row">Core Thickness</th>
              <td>{product.core_thickness}</td>
            </tr>
          )}
          {product.wearlayer && (
            <tr>
              <th scope="row">Wearlayer</th>
              <td>{product.wearlayer}</td>
            </tr>
          )}
          {product.backing && (
            <tr>
              <th scope="row">Attached Pad</th>
              <td>{product.backing}</td>
            </tr>
          )}
          {product.embossing && (
            <tr>
              <th scope="row">Embossing</th>
              <td>{product.embossing}</td>
            </tr>
          )}
          {product.plank_dimensions && (
            <tr>
              <th scope="row">Plank Dimensions</th>
              <td>{product.plank_dimensions}</td>
            </tr>
          )}
          {product.flooring_type && (
            <tr>
              <th scope="row">Flooring Type</th>
              <td>{product.flooring_type}</td>
            </tr>
          )}
          {product.edge_profile && (
            <tr>
              <th scope="row">Edge Profile</th>
              <td>{product.edge_profile}</td>
            </tr>
          )}
          {product.backing_facet && (
            <tr>
              <th scope="row">Backing</th>
              <td>{product.backing_facet}</td>
            </tr>
          )}
          {product.look && (
            <tr>
              <th scope="row">Look</th>
              <td>{product.look}</td>
            </tr>
          )}
          {product.installation_level && (
            <tr>
              <th scope="row">Installation Level</th>
              <td>{product.installation_level}</td>
            </tr>
          )}
          {product.warranty_text && (
            <tr>
              <th scope="row">Warranty</th>
              <td>{product.warranty_text}</td>
            </tr>
          )}
          {product.description && (
            <tr>
              <th scope="row">Description</th>
              <td>{product.description}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductAttributes;
